import * as AdminActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Normalized, Status } from "store/types";
import { Job } from "store/jobs/types";

export type AdminJobState = Normalized<Job, string> & {
  Job?: AdminJob;
  fetchJobStatus: Status;
  fetchJobFileStatus: Status;
  cloneJobInfo: { jobId: string; status: Status; errorMsg?: string };
  jobSampleData: { data: string[][]; status: Status; errorMsg?: string };
  jobAutoEnrichmentData?: {
    data?: JobAutoEnrichment;
    status: Status;
  };
  jobTransaction?: {
    data: JobTransaction[];
    status: Status;
    errorMsg?: string;
  };
  fetchJobEnrichmentsStatus: Status;
  enrichments?: jobEnrichments[];
};
export interface AdminJob {
  jobDetails: Job;
  jobMapping: { mapping: string; value: string }[];
  rawFile?: { name: string; downloadLink: string; hasHeader?: boolean };
  transformedFile?: { name: string; downloadLink: string; hasHeader?: boolean };
  validationFile?: { name: string; downloadLink: string; hasHeader?: boolean };
  jobEnrichmentSettings: {
    algorithmBanding: string[];
  };
  fileStatus?: {
    create_date: string;
    id: number;
    json_extra: string;
    modified_date: string;
    status: string;
  };
  jobSpendDetails?: jobSpendDetails[];
}
export interface jobSpendDetails {
  enrichmentSettings: enrichmentSettings;
  creditType: string;
  createdAt: string;
  totalCredits: number;
  spendDetails: spendDetails[];
}
export interface enrichmentSettings {
  algorithmBanding: string[];
  creditType: string;
  dE_Reason: {
    id: number;
    descripton: string;
  };
  headQuarters: string;
}
export interface spendDetails {
  country: string;
  credits: number;
}
export interface jobEnrichments {
  columnName: string;
  type: string;
}
export interface JobTransaction {
  status: string;
  timeStamp: string;
  message: string;
}

export interface JobAutoEnrichment {
  mmrPercentage: number;
  matchedPercentage?: number;
  mmrAppliedCountryCode: string;
  isRowLevelValidationFailed: boolean;
  creditType: string;
  dE_Reason: {
    id: number;
    descripton: string;
  };
  headQuarters: string;
  availableCreditsByCountry?: { country: string; credits: number }[];
  creditsRequiredByCountry?: { country: string; credits: number }[];
}
//added
export const FETCH_DASHBOARD_JOB_SELECTED_WIDGETS =
  "FETCH_DASHBOARD_JOB_SELECTED_WIDGETS";
export const FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_SUCCESS =
  "FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_SUCCESS";
export const FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_ERROR =
  "FETCH_DASHBOARD_JOB_SELECTED_WIDGETS_ERROR";

export const FETCH_DASHBOARD_JOB_DETAILS = "FETCH_DASHBOARD_JOB_DETAILS";
export const FETCH_DASHBOARD_JOB_DETAILS_SUCCESS =
  "FETCH_DASHBOARD_JOB_DETAILS_SUCCESS";
export const FETCH_DASHBOARD_JOB_DETAILS_ERROR =
  "FETCH_DASHBOARD_JOB_DETAILS_ERROR";

export const FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME =
  "FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME";
export const FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_SUCCESS =
  "FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_SUCCESS";
export const FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_ERROR =
  "FETCH_DASHBOARD_JOB_DETAILS_BY_JOB_NAME_ERROR";

export const RESET_DASHBOARD = "RESET_DASHBOARD";

export const CLONE_JOB = "CLONE_JOB";
export const CLONE_JOB_SUCCESS = "CLONE_JOB_SUCCESS";
export const CLONE_JOB_ERROR = "CLONE_JOB_ERROR";
export const UNSET_CLONE_JOB_ERROR = "UNSET_CLONE_JOB_ERROR";

export const FETCH_JOB_FILE = "FETCH_JOB_FILE";
export const FETCH_JOB_FILE_SUCCESS = "FETCH_JOB_FILE_SUCCESS";
export const FETCH_JOB_FILE_ERROR = "FETCH_JOB_FILE_ERROR";

export const FETCH_ADMIN_JOB_AUTO_ENRICHMENT =
  "FETCH_ADMIN_JOB_AUTO_ENRICHMENT";
export const FETCH_ADMIN_JOB_AUTO_ENRICHMENT_SUCCESS =
  "FETCH_ADMIN_JOB_AUTO_ENRICHMENT_SUCCESS";
export const FETCH_ADMIN_JOB_AUTO_ENRICHMENT_ERROR =
  "FETCH_ADMIN_JOB_AUTO_ENRICHMENT_ERROR";

export const FETCH_JOB_TRANSACTIONS = "FETCH_JOB_TRANSACTIONS";
export const FETCH_JOB_TRANSACTIONS_SUCCESS = "FETCH_JOB_TRANSACTIONS_SUCCESS";
export const FETCH_JOB_TRANSACTIONS_ERROR = "FETCH_JOB_TRANSACTIONS_ERROR";

export interface FetchJobFilePayload {
  id: string;
  uploadFileType?: string;
}
export interface CloneJobPayload {
  jobName: string;
  JobId: string;
  Mappings: { mapping: string; value: string }[];
}

export type AdminActionTypes = ActionType<typeof AdminActions>;
